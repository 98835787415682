import $ from 'jquery';
import Swiper from 'swiper';

$(window).on('load', () => {
  // eslint-disable-next-line no-unused-vars
  const courseSwiper = new Swiper('.top-works-items', {
    slidesPerView: 5,
    slidesPerGroup: 1,
    spaceBetween: 10,
    speed: 600,
    autoplay: {
      delay: 3000,
    },
    preventLinks: true,
    loop: true,
    loopFillGroupWithBlank: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1300: {
        slidesPerView: 4,
        slidesPerGroup: 1,
      },
      991: {
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
      767: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      543: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },
    onSlideChangeEnd: (s) => {
      s.fixLoop();
    },
  });
});
