import $ from 'jquery';

$(() => {
  if ($('#image_Modal').length) {
    $('#image_Modal').on('show.bs.modal', event => {
      const $images = $(event.relatedTarget)
        .parent()
        .find('.Pop-up')
        .attr('src');

      $('#image_Modal_img').attr('src', $images);
    });
  }
});
