import $ from 'jquery';

$(() => {
  if ($('.site-bottom-info-bar').length) {
    const $infoBar = $('.site-bottom-info-bar');

    // *** Func
    function hideSiteBottomInfoBar() {
      $infoBar.fadeOut(300);
    }

    function showSiteBottomInfoBar() {
      $infoBar
        .removeClass('d-none')
        .hide()
        .fadeIn(500);
    }

    function activeCookiePolicyApprovalStrage() {
      if ('sessionStorage' in window && window.sessionStorage !== null) {
        sessionStorage.setItem('cookiePolicyApproval', 'true');
      }
    }

    // SessionStrageの対応しているブラウザ
    // ------------------------------------
    if ('sessionStorage' in window && window.sessionStorage !== null) {
      // *** Setting
      const cookiePolicyApprovalVal = sessionStorage.getItem(
        'cookiePolicyApproval',
      );

      // *** Init
      // ユーザーがpolicy barに対し何のアクションも取らなかった場合
      // sessionStorageの発行すらされない。その場合barを表示状態に
      if (cookiePolicyApprovalVal === null) showSiteBottomInfoBar();

      $('.js-cookie-policy-accept').on('click', () => {
        activeCookiePolicyApprovalStrage();
        hideSiteBottomInfoBar();
        return false;
      });
    }
  }
});
