import $ from 'jquery';

class Worksmap {
  constructor() {
    this._worksInfo = $('.js-works-master-info').data('info');
    this.$modalTrigger = $('.js-enable-works-map-modal');

    // modal dom
    this.$modal = $('#worksMapModal');
    this.$prefInModalTitle = $('.js-works-map-modal-title-pref');
    this.$workItemsInModalBody = $('.js-modal-work-items-wrapper');

    this.$modalTrigger.on('click', e => {
      const $self = $(e.currentTarget);
      this.hundleOpenModal($self);
      return false;
    });
  }

  get worksInfo() {
    return this._worksInfo;
  }

  getThePrefWorksInfo(prefId) {
    const worksInfoOfThePref = [];
    this.worksInfo.forEach(item => {
      item.pref.forEach(pref => {
        if (pref === prefId) worksInfoOfThePref.push(item);
      });
    });
    return worksInfoOfThePref;
  }

  updateModalTitle($obj) {
    this.$prefInModalTitle.text($obj.data('pref-name-for-modal'));
  }

  updateModalBody($obj) {
    this.$workItemsInModalBody.html(this.getWorkItemTag($obj));
  }

  getWorkItemTag($obj) {
    const info = this.getThePrefWorksInfo($obj.data('pref-id'));
    let tags = '';

    info.forEach(item => {
      tags += '<div class="top-mapmodal-item cmn-card-item-wrapper col-12 col-sm-6 col-md-4">';
      tags += '<div class="cmn-card-item card">';
      tags += `<a class="cmn-card-item-imgs" href="${item.link_path}" target="_blank">`;
      tags += `<img class="cmn-card-item-imgs__img" src="${item.image_path}" alt="${item.title}" >`;
      tags += '</a>';
      tags += '<div class="cmn-card-item-body card-body">';
      tags += '<div class="cmn-card-item-body__inner">';
      tags += '<div class="cmn-card-item-body__ttls">';
      tags += `<p class="cmn-card-item-body__ttls-category text-uppercase">${item.cat}</p>`;
      tags += '<h3 class="cmn-card-item-body__ttls-ttl">';
      tags += `<a class="cmn-card-item-body__ttls-ttl-link" href="${item.link_path}" target="_blank">${item.title}</a>`;
      tags += '</h3>';
      tags += '</div>'; //.cmn-card-item-body__ttls
      tags += '<div class="cmn-card-item-body__leads">';
      tags += `<p class="cmn-card-item-body__leads-lead">${item.short_desctiption}</p>`;
      tags += '</div>'; //.cmn-card-item-body__leads
      tags += '</div>'; //.cmn-card-item-body
      tags += '</div>'; //.cmn-card-item-body__inner
      tags += '</div>';
      tags += '</div>';
    });

    return tags;
  }

  hundleOpenModal($obj) {
    this.$modal.modal(); // [note] modal method is extending jQuery method by Bootstrap
    this.updateModalTitle($obj);
    this.updateModalBody($obj);
  }
}

if (document.querySelector('.js-enable-works-map-modal') !== null) new Worksmap();
