class Header {
  constructor() {
    this.toggler = document.querySelector('.site-header-toggler__icon');
    this.target = document.querySelector('.site-header-nav-wrapper');
    this.faDefaultClassName = 'fa-bars';
    this.faActiveClassName = 'fa-times-circle';
    this.targetHideClassName = 'd-none';

    this.toggler.addEventListener('click', () => {
      this.updateHeaderState();
    });
  }

  updateHeaderState() {
    if (this.toggler.classList.contains(this.faDefaultClassName)) {
      this.showTarget();
    } else {
      this.hideTarget();
    }
  }

  showTarget() {
    this.target.classList.remove(this.targetHideClassName);
    this.toggler.classList.remove(this.faDefaultClassName);
    this.toggler.classList.add(this.faActiveClassName);
  }

  hideTarget() {
    this.target.classList.add(this.targetHideClassName);
    this.toggler.classList.remove(this.faActiveClassName);
    this.toggler.classList.add(this.faDefaultClassName);
  }
}

if (document.querySelector('.site-header-toggler__icon') !== null) new Header();
