import $ from 'jquery';
import Swiper from 'swiper';

// swiper
$(() => {
  if ($('.top-mv__inner').length) {
    const topSwiper = new Swiper('.top-mv__inner', {
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 3000,
      autoplayDisableOnInteraction: true,
      effect: 'fade',
      fade: {
        crossFade: true,
      },
      autoplay: {
        delay: 500,
      },
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }
});
